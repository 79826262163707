import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { FaPlay, FaStepForward } from "react-icons/fa";
import { SlLike } from "react-icons/sl";
import ReactLoading from "react-loading";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../redux/Baseurl";
import { useNavigate } from "react-router-dom";

const generateUniqueId = () => {
  return `rider_${new Date().getTime()}_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
};

const AdPage = () => {
  const location = useLocation();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [interested, setInterested] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAdWatched, setIsAdWatched] = useState(false);
  const [isPayed, setIsPayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [playNow, setPlayNow] = useState(false);
  const [adsArray, setAdsArray] = useState([]);
  const [noAdsAvailable, setNoAdsAvailable] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const networks = ["MTN", "VODAFONE", "AIRTELTIGO"];
  const navigate = useNavigate();

  useEffect(() => {
    if (noAdsAvailable) {
      const timeout = setTimeout(() => {
        navigate("/");
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [noAdsAvailable, navigate]);

  const handleRefresh = () => {
    window.location.reload();
  };
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    let riderId = localStorage.getItem("rideAd247_rider_identity");
    const storedDetails = localStorage.getItem("payment_details");
    if (storedDetails) {
      const details = JSON.parse(storedDetails);
      setPaymentNumber(details.phone);
      setSelectedNetwork(details.network);
    }
    if (!riderId) {
      riderId = generateUniqueId();
      localStorage.setItem("rideAd247_rider_identity", riderId);
    }

    const queryParams = new URLSearchParams(location.search);
    const driverId = queryParams.get("qFtKnNAu2IxWhmhzA");
    const filterAds = async () => {
      setIsPaying(false);
      try {
        const response = await axios.get(`${BASE_URL}/ads/filter_ads`, {
          params: {
            rider_id: riderId,
            driver_id: driverId
          }
        });

        if (!response.data.ad) {
          setNoAdsAvailable(true);
        } else {
          setAdsArray(response.data.ad);
        }
      } catch (error) {
        setNoAdsAvailable(true);
        toast.error(error.response.data.message);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    filterAds();
  }, [location]);

  const handleAdEnd = () => {
    setIsAdWatched(true);
    setPlayNow(false);
  };

  const handleClaim = async () => {
    try {
      const riderId = localStorage.getItem("rideAd247_rider_identity");
      const phone = paymentNumber.replace(/\s+/g, "");
      const queryParams = getQueryParams(window.location.search);
      const driverId = queryParams.get("qFtKnNAu2IxWhmhzA");

      if (!paymentNumber) {
        toast.info("Please enter a payment number.");
        return;
      }
      if (!selectedNetwork) {
        toast.info("Please select a network.");
        return;
      }

      const validPrefixes = [
        "24",
        "25",
        "53",
        "54",
        "55",
        "59",
        "20",
        "50",
        "27",
        "57",
        "26",
        "56"
      ];
      const phoneRegex = new RegExp(`^0(${validPrefixes.join("|")})\\d{7}$`);
      if (!phoneRegex.test(phone)) {
        toast.info(
          "Please enter a valid 10-digit phone number starting with 0."
        );
        return;
      }

      const prefix = phone.slice(1, 3);
      if (!validPrefixes.includes(prefix)) {
        toast.info("Invalid phone number prefix.");
        return;
      }
      if (
        adsArray.transactions?.some(
          (transaction) => transaction.phone === phone
        )
      ) {
        toast.info("You have already been paid for watching this ad.");
        return;
      }
      setIsPaying(true);
      const response = await fetch(`${BASE_URL}/disburse-funds`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          driverId,
          riderPhone: `233${phone.slice(1)}`,
          amount_for_each: adsArray.amount_for_each,
          rider_id: riderId,
          ad_id: adsArray.id,
          network: selectedNetwork,
          interested: interested
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to process payment.");
      }

      await response.json();
      setIsPaying(false);
      setIsAdWatched(false);
      toast.success(`You've been credited! ${adsArray.amount_for_each} GHC`);
      const paymentDetails = {
        phone: phone,
        network: selectedNetwork
      };
      localStorage.setItem("payment_details", JSON.stringify(paymentDetails));
      setIsPayed(true);
    } catch (error) {
      setIsPaying(false);
      console.error("Error processing payment:", error);
      toast.error(error.message || "Something went wrong");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "paymentNumber") {
      setPaymentNumber(value);
    }
  };

  return (
    <div className="flex flex-col items-center bg-black h-screen w-screen justify-center overflow-hidden">
      {isLoading ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-[#F46D21]">
          <ReactLoading
            type={"bars"}
            color={"white"}
            height={150}
            width={150}
          />
          <p className="text-white">Please Wait ...</p>
        </div>
      ) : noAdsAvailable ? (
        <div className="flex flex-col items-center justify-center text-white">
          <h2 className="text-2xl">No ads available at the moment.</h2>
          <p>Please check back later for more ads.</p>
        </div>
      ) : (
        <>
          <div className="w-full max-w-3xl h-full relative">
            <ReactPlayer
              url={adsArray.ad_url}
              controls={false}
              playing={playNow}
              onEnded={handleAdEnd}
              width="100%"
              height="100%"
              style={{
                maxHeight: "100%",
                margin: "0 auto",
                objectFit: "cover"
              }}
              onReady={(player) => {
                const videoElement = player.getInternalPlayer();
                if (videoElement) {
                  videoElement.setAttribute("playsinline", "true");
                  videoElement.removeAttribute("controls");
                }
              }}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                    playsInline: true
                  }
                }
              }}
            />
            <div className="absolute top-0 m-2 z-50 flex items-end gap-10 w-full">
              {adsArray.custom_link && (
                <a
                  href={adsArray.custom_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="py-2 px-4 rounded-lg bg-[#F46D21] text-white transition-all"
                >
                  Visit site
                </a>
              )}
              {!isAdWatched && !isPayed && (
                <button
                  className={`py-2 px-4 rounded-lg bg-[#F46D21] text-white transition-all ${
                    interested ? "bg-green-500" : ""
                  }`}
                  onClick={() =>
                    interested ? setInterested(false) : setInterested(true)
                  }
                >
                  <div className="flex gap-2 items-center">
                    <p>interested</p>
                    <SlLike />
                  </div>
                </button>
              )}
            </div>

            {!playNow && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <button
                  onClick={() => setPlayNow(true)}
                  className="p-4 rounded-full bg-[#F46D21] gap-10 transition-all flex justify-center items-center duration-300"
                >
                  <FaPlay className="text-white text-l" />
                </button>
                {isPayed && (
                  <button
                    onClick={handleRefresh}
                    className="p-4 rounded-full ml-6 bg-[#F46D21] transition-all flex justify-center items-center duration-300"
                  >
                    <FaStepForward className="text-white text-l" />
                  </button>
                )}
              </div>
            )}
          </div>

          {!isPayed && isAdWatched && (
            <div className="absolute bottom-0 pb-9 z-40 w-full flex flex-col items-center justify-center space-y-4 bg-white">
              <h2 className="text-lg text-white">Claim Your Payment</h2>

              <div
                className={`flex${
                  isPaying ? "justify-center" : "gap-3"
                } w-full px-4 max-w-lg`}
              >
                {!isPaying && (
                  <div>
                    <div className="flex gap-10 mb-5 items-center">
                      <h2 className="text-lg font-bold text-[#F46D21]">
                        Interested ?
                      </h2>
                      <div className="flex gap-5">
                        <button
                          className={`border-2 py-2 px-6 rounded-xl font-bold ${
                            interested === true
                              ? "border-[#F46D21] bg-[#F46D21] text-white"
                              : "border-gray-400 text-gray-400"
                          }`}
                          onClick={() => setInterested(true)}
                        >
                          Yes
                        </button>
                        <button
                          className={`border-2 py-2 px-6 rounded-xl font-bold ${
                            interested === false
                              ? "border-[#F46D21] bg-[#F46D21] text-white"
                              : "border-gray-400 text-gray-400"
                          }`}
                          onClick={() => setInterested(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div className="relative w-full max-w-lg mb-4">
                      <button
                        className="w-full bg-[#F46D21] text-white text-lg font-bold py-3 px-4 rounded-lg flex justify-between items-center"
                        onClick={() => setDropdownOpen((prev) => !prev)}
                      >
                        {selectedNetwork || "Select Network"}
                        <span>&#9662;</span>
                      </button>
                      {dropdownOpen && (
                        <div className="absolute top-full left-0 w-full bg-white shadow-lg rounded-lg z-10">
                          {networks.map((network) => (
                            <div
                              key={network}
                              onClick={() => {
                                setSelectedNetwork(network);
                                setDropdownOpen(false);
                              }}
                              className="px-4 py-2 text-black hover:bg-[#F46D21] hover:text-white cursor-pointer"
                            >
                              {network}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col gap-10 mb-10">
                      <input
                        type="text"
                        name="paymentNumber"
                        placeholder="Enter your phone number"
                        value={paymentNumber}
                        onChange={handleInputChange}
                        className="p-4 border-2 border-[#F46D21] bg-transparent text-black font-bold text-s w-full rounded-xl text-lg outline-none"
                      />
                    </div>
                  </div>
                )}

                <div
                  onClick={!isPaying ? handleClaim : undefined}
                  className={`flex p-[15px] justify-center items-center rounded-2xl w-full 
                 ${
                   isPaying
                     ? "bg-[#F8A95C] cursor-not-allowed"
                     : "bg-[#F46D21] cursor-pointer"
                 }`}
                  disabled={isPaying}
                >
                  <h2 className="text-lg font-bold text-white">
                    {isPaying ? "Processing..." : "Claim"}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdPage;

