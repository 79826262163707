import { successToast, errorToast } from "../components/CustomToast";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const UPDATE_DETAILS = "UPDATE_DETAILS";

// Action Creators
export const login = (user) => ({ type: LOGIN, payload: user });
export const logout = () => ({ type: LOGOUT });
export const updateDetails = (details) => ({
  type: UPDATE_DETAILS,
  payload: details
});

export const updateProfile = (id, updatedData) => async (dispatch) => {
  try {
    const response = await fetch(
      `https://api.ridead247.com/drivers/${id}/update_profile`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          driver: {
            first_name: updatedData.firstName,
            last_name: updatedData.lastName,
            phone: updatedData.phoneNumber
          }
        })
      }
    );

    // Handle the response
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || response.statusText;
      throw new Error(`Profile update failed: ${errorMessage}`);
    }

    const data = await response.json();

    // Dispatch the update action with the new data
    dispatch({
      type: UPDATE_DETAILS,
      payload: data.driver // Assuming the response returns the updated driver data
    });
    // Show a success message
    successToast("Profile updated successfully!");
  } catch (error) {
    console.error("Error during profile update:", error);
    errorToast(
      `Error: ${error.message || "An error occurred during profile update"}`
    );
  }
};

