import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SparklesCore } from "../components/ui/sparkles";
import { FlipWords } from "../components/ui/flip-words";
import { TextGenerateEffect } from "../components/ui/text-generate-effect";

export default function Home() {
  const FlipWordswords = ["RideAd", "TrendAd", "BuyAd"];
  const TextGenerateEffectwords = `Watch advertisements and get paid instantly! Join the innovation today.`;

  return (
    <div className="h-screen flex flex-col ">
      <div className="h-full w-full bg-[#0d0d0d] flex flex-col items-center justify-center overflow-hidden">
        {/* <header className="mb-10">
          <img
            src={require("../images/rideadLogo.png")}
            alt="RideAd Logo"
            className="h-[50px] w-[70px] rounded-lg"
          />
        </header> */}
        <h1 className="md:text-7xl text-5xl lg:text-5xl font-bold text-center text-white relative z-20">
          Welcome to
          <FlipWords words={FlipWordswords} />
        </h1>
        <div className="flex flex-col items-center justify-center w-[70%]">
          <TextGenerateEffect words={TextGenerateEffectwords} />
        </div>
        <div className="w-[40rem] h-40 relative">
          {/* Gradients */}
          <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
          <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
          <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
          <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

          {/* Core component */}
          <SparklesCore
            background="transparent"
            minSize={0.4}
            maxSize={1}
            particleDensity={1200}
            className="w-full h-full"
            particleColor="#FFFFFF"
          />

          {/* Radial Gradient to prevent sharp edges */}
          <div className="absolute inset-0 w-full h-full bg-[#0d0d0d] [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col w-full items-center justify-center md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 mt-10">
            <a
              href="mailto:support@ridead247.com"
              className="w-[90%] md:w-56 font-bold h-12 rounded-xl border dark:border-white border-transparent text-white text-sm flex items-center justify-center"
            >
              support@ridead247.com
            </a>
            <Link
              to="/login"
              className="w-[90%] md:w-52 font-bold h-12 rounded-xl bg-[#f46d21] text-white border border-[#f46d21] text-sm flex items-center justify-center"
            >
              Login As A Driver
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

