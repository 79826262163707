import React from "react";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { PowerIcon } from "@heroicons/react/24/solid";

const Dashboard = () => {
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const adLink = `https://ridead247.com/play-ad?qFtKnNAu2IxWhmhzA=${user.driver.id}`;

  const handleLogout = () => {
    localStorage.removeItem("rideAd247-user");
    navigate("/");
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="absolute top-4 right-4">
        <button
          onClick={handleLogout}
          className="p-2 rounded-full bg-gray-300 hover:bg-gray-400"
        >
          <PowerIcon className="h-6 w-6 text-gray-700" />
        </button>
      </div>

      <h1 className="text-l mt-4 font-bold">{user.driver.user_type}</h1>

      <div className="p-4 bg-white shadow-lg rounded-lg">
        <QRCodeCanvas value={adLink} size={300} />
      </div>
      {user.driver.user_type === "RideAd" ? (
        <h1 className="text-l mt-4 font-bold">
          {user.driver.first_name} {user.driver.last_name} - {user.driver.phone}
        </h1>
      ) : (
        <h1 className="text-l mt-4 font-bold">{user.driver.first_name}</h1>
      )}
    </div>
  );
};

export default Dashboard;

